<template>
  <div class="serviceCharge">
    <div>
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane :label="item.label" :name="item.name" v-for="(item, index) in tabs" :key="index"></el-tab-pane>
      </el-tabs>
      <!-- 搜索框 -->
      <div class="search-box">
        <div class="input-box" v-if="activeName === '3' || activeName === '1'">
          <el-cascader v-model="value" :options="options" collapse-tags :props="{ expandTrigger: 'hover', label: 'categoryName', value: 'categoryId', children: 'categoryVoList', checkStrictly: true, emitPath: false }" @change="onScreenData" clearable placeholder="搜索全部分类"></el-cascader>
        </div>
        <div class="input-box" v-if="activeName === '2' || activeName === '1'">
          <el-input v-model="brand" placeholder="搜索品牌" @change="onScreenData" clearable></el-input>
        </div>
        <div class="input-box" v-if="activeName === '1'">
          <el-input v-model="codeValue" placeholder="搜索商品条形码" @change="onScreenData" clearable></el-input>
        </div>
        <!-- <div class="input-box" v-if="activeName === '1'">
          <el-input v-model="serialId" @change="onScreenData" placeholder="搜索商品编号" clearable></el-input>
        </div> -->
        <div class="input-box" v-if="activeName === '1'">
          <el-input v-model="productName" @change="onScreenData" placeholder="搜索商品名称" clearable></el-input>
        </div>
        <div class="input-box">
          <el-button type="primary" @click="handleAdd">批量设置服务费率</el-button>
        </div>
      </div>
      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column prop="number" label="序号" :width="activeName == 4 ? '' : '80'" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <template v-for="(item, index) in newtableLabel">
            <el-table-column :prop="item.prop" :label="item.label" show-overflow-tooltip :key="index" v-if="item.label != '操作'"></el-table-column>
            <el-table-column label="操作" header-align="center" align="center" width="200" :key="`else-${index}`" v-if="item.label == '操作'">
              <template slot-scope="scope">
                <el-button class="btn" type="primary" plain @click="handleDetail(scope.row)">查看</el-button>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </div>
      <!-- 分页 -->
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "1",
      value: "", //全部分类
      brand: "", //品牌
      serialId: "", //商品编号
      productName: "", //商品名称
      codeValue: "", // 商品条形码
      tableData: [],
      newtableLabel: [],
      tableLabel: [
        // { prop: "serialId", label: "商品编号", activeName: ["1"] },
        { prop: "productName", label: "商品名称", activeName: ["1"] },
        { prop: "categoryName", label: "分类", activeName: ["1"] },
        { prop: "brand", label: "品牌", activeName: ["1", "2"] },
        { prop: "barCode", label: "商品条形码", activeName: ["1"] },
        { prop: "firstCategoryName", label: "一级类目", activeName: ["3"] },
        { prop: "secondCategoryName", label: "二级类目", activeName: ["3"] },
        { prop: "feeRate", label: "技术服务费率（%）", activeName: ["1", "2", "3", "4"] },
        { prop: "updatedTime", label: "设置时间", activeName: ["1", "2", "3", "4"] },
        { prop: "validityPeriod", label: "操作", activeName: ["2", "3"] },
      ],
      options: [], //全部分类
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      tabs: [
        { label: "按商品设置", name: "1" },
        { label: "按品牌设置", name: "2" },
        { label: "按品类设置", name: "3" },
        { label: "全部设置", name: "4" },
      ],
    }
  },

  created() {
    this.handleClick()
    this.getCategoryInfoList()
  },
  methods: {
    handleClick() {
      // tableLabel数组过滤activeName等于this.activeName的元素
      this.newtableLabel = this.tableLabel.filter(item => item.activeName.includes(this.activeName))
      if (this.activeName == "1") {
        this.productTechFeeRatePageList()
      }
      if (this.activeName == "2") {
        this.brandTechFeeRatePageList()
      }
      if (this.activeName == "3") {
        this.categoryTechFeeRatePageList()
      }
      if (this.activeName == "4") {
        this.totalTechFeeRatePageList()
      }
    },
    handleAdd() {
      this.$router.push({
        path: "/technicalServiceFee/detail/serviceSetup",
        // query: { activeName: this.activeName },
      })
    },
    handleDetail(row) {
      // this.activeName 添加进row.aciveName
      row.activeName = this.activeName
      // console.log(row);
      localStorage.setItem("serviceInfo", JSON.stringify(row))
      this.$router.push({
        path: "/technicalServiceFee/detail/serviceInfo",
        // query: { bizId: row.bizId, bizType: row.bizIdType, },
      })
    },
    // 商品分类
    getCategoryInfoList() {
      this.$axios
        .get(this.$api.categoryListPC, {
          // params: {
          //   auditStatus: '1',
          //   page: 1,
          //   pageSize: 9999,
          // }
        })
        .then(res => {
          if (res.data.code == 100) {
            this.options = res.data.result.list
          }
          this.loading = false
        })
    },
    // 商品list
    productTechFeeRatePageList() {
      this.$axios
        .get(this.$api.productTechFeeRatePageList, {
          params: {
            page: this.currentPage,
            pageSize: this.pageSize,
            barCode: this.codeValue, //商品条形码
            brand: this.brand, //品牌
            categoryId: this.value, //分类id
            serialId: this.serialId, //商品编号
            productName: this.productName, //商品名称
            // firstCategoryName:null,//一级类目
            // secondCategoryName:null,//二级类目
            // techFeeRateStatus,//是否设置费率：0是；1否
          },
        })
        .then(res => {
          this.tableData = res.data.result.list
          this.totalItemsCount = res.data.result.totalCount
        })
    },
    // 品牌list
    brandTechFeeRatePageList() {
      this.$axios
        .get(this.$api.brandTechFeeRatePageList, {
          params: {
            page: this.currentPage,
            pageSize: this.pageSize,
            barCode: this.codeValue, //商品条形码
            brand: this.brand, //品牌
            categoryId: this.value, //分类id
            serialId: this.serialId, //商品编号
            productName: this.productName, //商品名称
            // firstCategoryName:null,//一级类目
            // secondCategoryName:null,//二级类目
            // techFeeRateStatus,//是否设置费率：0是；1否
          },
        })
        .then(res => {
          this.tableData = res.data.result.list
          this.totalItemsCount = res.data.result.totalCount
        })
    },
    // 分类list
    categoryTechFeeRatePageList() {
      this.$axios
        .get(this.$api.categoryTechFeeRatePageList, {
          params: {
            page: this.currentPage,
            pageSize: this.pageSize,
            barCode: this.codeValue, //商品条形码
            brand: this.brand, //品牌
            categoryId: this.value, //分类id
            serialId: this.serialId, //商品编号
            productName: this.productName, //商品名称
            // firstCategoryName:null,//一级类目
            // secondCategoryName:null,//二级类目
            // techFeeRateStatus,//是否设置费率：0是；1否
          },
        })
        .then(res => {
          this.tableData = res.data.result.list
          this.totalItemsCount = res.data.result.totalCount
        })
    },
    // 全部list
    totalTechFeeRatePageList() {
      this.$axios
        .get(this.$api.totalTechFeeRatePageList, {
          params: {
            page: this.currentPage,
            pageSize: this.pageSize,
            barCode: this.codeValue, //商品条形码
            brand: this.brand, //品牌
            categoryId: this.value, //分类id
            serialId: this.serialId, //商品编号
            productName: this.productName, //商品名称
            // firstCategoryName:null,//一级类目
            // secondCategoryName:null,//二级类目
            // techFeeRateStatus,//是否设置费率：0是；1否
          },
        })
        .then(res => {
          this.tableData = res.data.result.list
          this.totalItemsCount = res.data.result.totalCount
        })
    },
    // 筛选
    onScreenData() {
      this.currentPage = 1
      this.tableData = []
      if (this.activeName == "1") {
        this.productTechFeeRatePageList()
      }
      if (this.activeName == "2") {
        this.brandTechFeeRatePageList()
      }
      if (this.activeName == "3") {
        this.categoryTechFeeRatePageList()
      }
      if (this.activeName == "4") {
        this.totalTechFeeRatePageList()
      }
    },
    // 换页
    handleCurrentChange() {
      this.page += 1
      this.tableData = []
      if (this.activeName == "1") {
        this.productTechFeeRatePageList()
      }
      if (this.activeName == "2") {
        this.brandTechFeeRatePageList()
      }
      if (this.activeName == "3") {
        this.categoryTechFeeRatePageList()
      }
      if (this.activeName == "4") {
        this.totalTechFeeRatePageList()
      }
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.pageSize = e
      this.onScreenData()
    },
  },
}
</script>

<style lang="scss" scoped>
.serviceCharge {
  padding: 20px 36px 30px;

  ::v-deep .el-tabs--card > .el-tabs__header {
    border-bottom: none !important;
  }

  ::v-deep .el-tabs__item {
    color: #707070 !important;
  }

  ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item {
  }

  ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: 1px solid #e2e2e2;
  }

  ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
    background: #069bbc;
    color: #ffffff !important;
    border-bottom: none !important;
  }

  ::v-deep .el-tabs__item:hover {
    color: #707070 !important;
  }

  .search-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;

    .input-box {
      margin-left: 20px;
      margin-bottom: 20px;
    }
  }
}
</style>
